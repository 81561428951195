<template>
  <Layout ref="main">
    <div class="row">
      <div class="col-12 mt-5">

        <b-tabs
        justified
        nav-class="nav-tabs-custom3 mb-3 mt-4"
        content-class="text-muted"
        @activate-tab="tabChange"
        v-model="tabSelected"
      >
        <b-tab>
          <template v-slot:title>
          </template>
          <div class="tabcontent">
            <div class="row">
              <div class="col-12">
                <h2 class="text-primary font-size-22">{{$t('siteLang.LoanDetail')}}</h2>
              </div>
              <div class="col-12">
                <div class="card p-3 text-muted mb-3" >
                  <div class="container p-0">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">{{$t('siteLang.LoanAmount')}} (Rp)</div>
                        <div class="mb-1">{{$t('siteLang.LoanTerm')}}</div>
                        <div class="mb-1">{{$t('siteLang.InterestRate')}}</div>
                        <div class="mb-1">{{$t('siteLang.MonthlyRepayment')}} (Rp)</div>
                        <div>{{$t('siteLang.LoanDate')}} </div>
                      </div>
                      <div class="col">
                        <div class="mb-1 data-color fw-medium">Rp. {{loan_detail.loanAmount || '0.00'}}</div>
                        <div class="mb-1 data-color fw-medium">{{loan_detail.loanPeriod || '0'}} {{$t('siteLang.Months')}}</div>
                        <div class="mb-1 data-color fw-medium">{{ loan_detail.interestRate || '0%' }}</div>
                        <div class="mb-1 data-color fw-medium">Rp. {{ loan_detail.monthlyRepayment  || '0.00'}}</div>
                        <div class="data-color fw-medium">{{ loan_detail.loanDate  || '-'}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-3 px-0 mb-0 form-check text-start text-muted">
                <input type="checkbox" class="form-check-input float-none me-2"
                v-model="confirmLoanAmout" id="agree">
                <label class="form-check-label  d-inline fw-normal font-size-14 text-muted" for="agree">{{ $t('siteLang.IHaveConfirmLoanAmount') }} </label>
                </div>
              </div>
            </div>
          </div>
          
        </b-tab>
        <b-tab>
          <template v-slot:title>
          </template>
          <div class="tabcontent">
            <div class="row">
              <div class="col-12">
                <h2 class="text-primary font-size-22">{{ $t('siteLang.myInfo') }}</h2>
                <p class="text-muted"><i class="mdi mdi-file-lock-outline"></i> {{$t('siteLang.Yourpersonalinforsafe')}}</p>
              </div>
              
              <div class="col-12">
                <div class="card p-3 text-muted mb-4">
                
                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi mdi-account text-primary me-1 font-size-16"></i> {{$t('siteLang.FullName')}} </label>
                    <input type="text" class="form-control" v-model.trim="formData.name"
                    :class="{
                      'is-invalid': submitted && $v.formData.name.$error,
                    }"
                    :placeholder="$t('siteLang.FullName')">
                    <div
                      v-if="submitted && $v.formData.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.name.required"
                        >{{$t('siteLang.FullName')}} {{$t('siteLang.isRequired')}}</span
                      >
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi text-primary mdi-cellphone-iphone me-1 font-size-16"></i> {{$t('siteLang.PhoneNumber')}}</label>
                    <input type="tel" class="form-control" placeholder="62xxxxxxxxxx"  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
                    v-model="formData.phone"
                    :disabled="formData.phone"
                    :class="{
                      'is-invalid': submitted && $v.formData.phone.$error,
                    }">
                    <div
                      v-if="submitted && $v.formData.phone.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.phone.required"
                        >{{$t('siteLang.PhoneNumber')}} {{$t('siteLang.isRequired')}}</span
                      >
                      <span v-if="!$v.formData.phone.countryCodeAndPhone">
                        {{$t('siteLang.PhoneMustError')}}
                      </span>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi text-primary mdi-map-marker me-1 font-size-16"></i> {{$t('siteLang.Address')}} </label>
                    <input type="text" class="form-control" v-model="formData.address"
                    :class="{
                      'is-invalid': submitted && $v.formData.address.$error,
                    }"
                    :placeholder="$t('siteLang.Address')">
                    <div
                      v-if="submitted && $v.formData.address.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.address.required"
                        >{{$t('siteLang.Address')}} {{$t('siteLang.isRequired')}}</span
                      >
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi text-primary mdi-school me-1 font-size-16"></i> {{$t('siteLang.EducationLevel')}}  </label>
                    <select class="form-select" v-model="formData.educationLevel"
                    :class="{
                      'is-invalid': submitted && $v.formData.educationLevel.$error,
                    }">
                      <option value="">{{$t('siteLang.Select')}}  </option>
                      <option v-for="(value, index) in education" :key="index" :value="value.value">{{value.title}}</option>
                    </select>
                    <div
                      v-if="submitted && $v.formData.educationLevel.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.educationLevel.required"
                        >{{$t('siteLang.PleaseSelectEducationLevel')}} </span
                      >
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi text-primary mdi-briefcase me-1 font-size-16"></i> {{$t('siteLang.Occupation')}} </label>
                    <input type="text" class="form-control" v-model="formData.occupation"
                    :class="{
                      'is-invalid': submitted && $v.formData.occupation.$error,
                    }"
                    :placeholder="$t('siteLang.Occupation')">
                    <div
                      v-if="submitted && $v.formData.occupation.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.occupation.required"
                        >{{$t('siteLang.PleaseEnterOccupation')}}</span
                      >
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi text-primary mdi-cash-usd me-1 font-size-16"></i> {{$t('siteLang.HowMuchIncome')}} </label>
                    <select class="form-select" v-model="formData.income"
                    :class="{
                      'is-invalid': submitted && $v.formData.income.$error,
                    }">
                      <option value="">{{$t('siteLang.Choose')}} </option>
                      <option v-for="(value, index) in incomeRange" :key="index" :value="value.value">{{value.title}}</option>
                    </select>
                    <div
                      v-if="submitted && $v.formData.income.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.income.required"
                        >{{$t('siteLang.PleaseIncomeRange')}}. </span
                      >
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi text-primary mdi-home me-1 font-size-16"></i> {{$t('siteLang.HaveHouse')}}</label>
                    <div>
                    <input type="radio" class="btn-check"
                    :class="{
                      'is-invalid': submitted && $v.formData.house.$error
                    }"
                    name="house" id="no-house" value="false" v-model="formData.house">
                    <label class="btn btn-outline-primary px-4 py-1 me-2" for="no-house">{{$t('siteLang.No')}}</label>

                    <input type="radio" class="btn-check" name="house" id="yes-house" value="true" v-model="formData.house">
                    <label class="btn btn-outline-primary  px-4 py-1" for="yes-house">{{$t('siteLang.Yes')}}</label>
                    <div
                      v-if="submitted && $v.formData.house.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.house.required"
                        >{{$t('siteLang.PleaseSelectAnwser')}} </span
                      >
                    </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                    <i class="mdi text-primary mdi-car me-1 font-size-16"></i>{{$t('siteLang.HaveCar')}}</label>
                    <div>
                    <input type="radio" class="btn-check" :class="{
                      'is-invalid': submitted && $v.formData.car.$error
                    }" name="car" id="no-car" value="false" v-model="formData.car">
                    <label class="btn btn-outline-primary px-4 py-1 me-2" for="no-car">{{$t('siteLang.No')}}</label>

                    <input type="radio" class="btn-check" name="car" id="yes-car" value="true" v-model="formData.car">
                    <label class="btn btn-outline-primary  px-4 py-1" for="yes-car">{{$t('siteLang.Yes')}}</label>
                    <div
                      v-if="submitted && $v.formData.car.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.car.required"
                        >{{$t('siteLang.PleaseSelectAnwser')}} </span
                      >
                    </div>
                    </div>
                  </div>
                </div>
                <div class="card p-3 ">
                  <h2 class="text-primary font-size-20 mb-2 py-2">{{$t('siteLang.BankAccountDetails')}}</h2>
                    <div class="form-group mb-3">
                      <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                      <i class="mdi mdi-bank text-primary me-1 font-size-16"></i> {{$t('siteLang.BankName')}} </label>
                      <div class="row mb-3 px-1">
                        <div class="col-4 col-md-2 px-0 bankRadio position-relative" v-for="(value, index) in Bank_name" :key="index">
                          <div class="form-check">
                            <i class="mdi mdi-checkbox-marked-circle position-absolute text-primary end-0 top-0 me-2 mt-0 font-size-20" v-if="selectedBank == value"></i>
                            <input class="form-check-input" type="radio" @change="changeBank()" name="bankName" :id="index" :value="value" v-model="selectedBank">
                            <label class="form-check-label rounded" :for="index">
                             <img :src="require('@/assets/images/app/bank/' + value.img)" class="img-fluid">
                             <!-- <div class="d-block text-center font-size-11">{{value.label}}</div> -->
                            </label>
                          </div>
                        </div>
                      </div>
                    
                      <input type="text" class="form-control" disabled :value="returnBankName(formData.bankName)"
                      :class="{
                        'is-invalid': submitted && $v.formData.bankName.$error,
                      }"
                      :placeholder="$t('siteLang.BankName')">
                      <div
                        v-if="submitted && $v.formData.bankName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.bankName.required"
                          >{{$t('siteLang.BankName')}} {{$t('siteLang.isRequired')}}</span
                        >
                      </div>
                    </div>
                    <div class="form-group mb-3">
                      <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                      <i class="mdi mdi-credit-card text-primary me-1 font-size-16"></i> {{$t('siteLang.AccountNumber')}}  </label>
                      <input type="tel" class="form-control" v-model="formData.accountNumber"
                      :class="{
                        'is-invalid': submitted && $v.formData.accountNumber.$error,
                      }"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :placeholder="$t('siteLang.AccountNumber')">
                      <div
                        v-if="submitted && $v.formData.accountNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.accountNumber.required"
                          > {{$t('siteLang.AccountNumber')}}  {{$t('siteLang.isRequired')}}</span
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                      <i class="mdi mdi-account text-primary me-1 font-size-16"></i> {{$t('siteLang.AccountName')}}  </label>
                      <input type="text" class="form-control" v-model="formData.acountName" :disabled="sameAsFullName"
                      :class="{
                        'is-invalid': submitted && $v.formData.acountName.$error,
                      }"
                      :placeholder="$t('siteLang.AccountName')">
                      <div class="form-check ms-0 ps-0 mt-2">
                        <input class="form-check-input mt-1" type="checkbox" id="same" v-model="sameAsFullName" @change="checkboxChanged">
                        <label class="form-check-label fw-normal ms-2 mt-1" for="same">
                          {{$t('siteLang.SameFullName')}}
                        </label>
                      </div>
                      <div
                        v-if="submitted && $v.formData.acountName.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.acountName.required"
                          >{{$t('siteLang.AccountName')}} {{$t('siteLang.isRequired')}}</span
                        >
                      </div>

                    </div>
                  </div>
                </div>
            </div>
          </div>
        </b-tab>
        
        <b-tab>
          <template v-slot:title>
          </template>
       
          <div class="tabcontent" >
            <div class="row">
              <div class="col-12">
                <h2 class="text-primary font-size-22 mb-2 py-2 position-relative">{{$t('siteLang.IdentificationCardSignature')}}</h2>
                <div class="form-group mb-3 card p-3">
                  <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                  <i class="mdi mdi-card-account-details-outline text-primary me-1 font-size-16"></i> {{$t('siteLang.IdentificationCard')}}</label>
                  
                  <input type="tel" class="form-control" maxlength="16" v-model="documentation.icNumber"
                  oninput="this.value = this.value.replace(/[^+0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
                  :class="{
                    'is-invalid': submitted2 && $v.documentation.icNumber.$error,
                  }"
                  :placeholder="$t('siteLang.IdentificationCard')">
                  <div
                    v-if="submitted2 && $v.documentation.icNumber.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.documentation.icNumber.minLength"> {{$t('siteLang.IdentityCardMustBeAtLeast')}} {{$v.documentation.icNumber.$params.minLength.min}} {{$t('siteLang.digitsLong')}}</span>
                    <span v-if="!$v.documentation.icNumber.maxLength"> {{$t('siteLang.IdentityCarMustBe')}} {{$v.documentation.icNumber.$params.max}} {{$t('siteLang.digitsOrFewer')}}</span>
                    <span v-if="!$v.documentation.icNumber.required"
                      >{{$t('siteLang.IdentificationCard')}} {{$t('siteLang.isRequired')}}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-3 text-center">
              
                <!--@click="$refs.fileInput.click()"-->
                <div
                  id="preview"
                  class="mb-3 rounded bg-white card p-3 position-relative" :class="{
                    'border border-danger animate__animated animate__shakeX is-invalid': submitted2 && $v.documentation.icFront.$error
                  }"
                  @click="currentImageSelectedFunc('front')"
                >
                  <img
                    v-if="documentation.icFront"
                    class="rounded img-fluid mb-2"
                    :src="documentation.icFront"
                  />
                  <!-- <img v-else src="" @click="$refs.fileInput.click()"> -->

                  <div v-else class="d-flex align-items-center p-3 m-2">
                    <div class="text-center w-100">
                      <h4 class="fw-normal text-primary text-center">
                        <i class="mdi mdi-card-account-details-outline mb-3 display-1"></i>
                        <small class="font-size-14 d-block text-body"
                          >{{$t('siteLang.ClickUploadFrontIDCard')}}</small
                        >
                      </h4>
                    </div>
                  </div>
                  <div class="position-relative text-center">
                  <image-Uploader
                  :class="['input-group']"
                  ref="fileInput"
                  id="image-input"
                  :maxWidth="1600"
                  :maxHeight="1600"
                  :quality="0.8"
                  outputFormat="base64"
                  :multiple="false"
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  :hidden="true"
                  @onUpload="startFiles"
                  @onComplete="viewAllFiles"
                ></image-Uploader>
                  <button class="btn btn-primary mb-2"><i class="uil uil-upload"></i> {{$t('siteLang.UploadIDCard')}}</button>
                    <button class="btn mb-2 ms-2 btn-outline-light bg-light text-muted overflow-hidden fileInput" v-if="documentation.icFrontFileName">
                    <i class="mdi mdi-file text-primary"></i> {{documentation.icFrontFileName}}</button>
                  </div>
                </div> 
               

               
              </div>
              <div class="col-12 col-md-6 mb-3">
                <div
                  id="preview2"
                  class="mb-3 p-3 rounded bg-white card position-relative" :class="{
                    'border border-danger animate__animated animate__shakeX is-invalid': submitted2 && $v.documentation.icBack.$error
                  }"
                  @click="currentImageSelectedFunc('back')"
                >
              
                  <img
                    v-if="documentation.icBack"
                    class="rounded img-fluid mb-2"
                    :src="documentation.icBack"
                  />
                  <!-- <img v-else src="" @click="$refs.fileInput.click()"> -->

                  <div v-else class="d-flex align-items-center p-3 m-2">
                    <div class="w-100 text-center">
                      <h4 class="fw-normal text-primary text-center">
                        <i class="mdi mdi-card-account-details-outline mb-3 display-1"></i>
                        <small class="font-size-14 d-block text-body"
                          >{{$t('siteLang.ClickUploadBackIDCard')}}</small
                        >
                      </h4>
                    </div>
                  </div>
                  <div class="position-relative text-center">
                    <image-Uploader
                    :class="['input-group']"
                    ref="fileInput2"
                    id="image-input2"
                    :maxWidth="1600"
                    :maxHeight="1600"
                    :quality="0.8"
                    outputFormat="base64"
                    :multiple="false"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    :hidden="true"
                    @onUpload="startFiles"
                    @onComplete="viewAllFiles"
                  ></image-Uploader>
                    <button class="btn btn-primary mb-2"><i class="uil uil-upload"></i> {{$t('siteLang.UploadIDCard')}}</button>
                    <button class="btn btn btn-outline-light mb-2 ms-2 bg-light text-muted overflow-hidden fileInput" v-if="documentation.icBackFileName">
                    <i class="mdi mdi-file text-primary"></i> {{documentation.icBackFileName}}</button>
                </div>
                </div> 
              
             </div>
             <div class="col-12">
                 
              <h2 class="text-primary font-size-22 mb-2 py-2 position-relative">{{$t('siteLang.ElectronicSignature')}} <button type="button" @click="clearSignature" class="btn bg-white text-primary font-size-14 fw-medium position-absolute top-0 end-0"><i class="mdi mdi-eraser"></i> {{$t('siteLang.ClearSignature')}}</button></h2>

             </div>

            </div>
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
          </template>
          <div class="tabcontent">
            <div class="row">
              <div class="col-12">
                <h2 class="text-primary font-size-22">{{$t('siteLang.Agreement')}}</h2>
              </div>
              <div class="col-12">
                <div class="card p-3 text-muted mb-4">
                  <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                  <i class="mdi mdi-file-document-edit-outline text-primary me-1 font-size-16"></i> {{$t('siteLang.Agreement')}}</label>
                  <table class="table table-bordered  border border-light align-middle font-size-11 table-sm" v-if="$t('siteLang.agreement')=='en'">
                  <tbody>
                    <tr>
                      <td>Full Name</td>
                      <td>{{formData.name}}</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td>{{loan_detail.loanDate}}</td>
                    </tr>
                    <tr>
                      <td>Identification Card</td>
                      <td>{{documentation.icNumber}}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{{formData.phone}}</td>
                    </tr>
                    <tr>
                      <td>Loan Amount</td>
                      <td>{{loan_detail.loanAmount}}</td>
                    </tr>
                    <tr>
                      <td>Loan Term</td>
                      <td>{{loan_detail.loanPeriod}}</td>
                    </tr>
                    <tr>
                      <td>Annual Interest Rate</td>
                      <td>{{loan_detail.interestRate}}</td>
                    </tr>
                    <tr>
                      <td>Disbursement Date</td>
                      <td>10th of each month</td>
                    </tr>
                    <tr>
                      <td>Payment Method</td>
                      <td>Automatic deduction from the borrower's bank account on the 10th of each month</td>
                    </tr>
                    <tr>
                      <td>Loan Agreement and Terms</td>
                      <td class="p-0">
                        <div class="border-bottom border-light p-1 fw-medium">
                          You and the Financial Institution sign this Contract so that the Financial Institution grants you a personal loan ("Loan") once you meet all the conditions established by the Financial Institution. IMPORTANT: Please read these documents carefully. Your signature on this Contract implies that you agree with the Schedule and Summary Sheet. You must pay the Financial Institution the principal, compensatory interest, default interest, or any other concept that current regulations allow to apply in case of non-payment, insurance, commissions, expenses, and other concepts described in the Summary Sheet.
                        </div>
                        <div class="border-bottom border-light p-1">
                          <p>The capital is the amount that the Financial Institution lends you. The compensatory interests are the ones that the Financial Institution receives for the amount lent. The moratory interests or any other concept that the current regulations allow to apply in case of non-compliance in the payment is the additional amount that you will pay if you delay in the payment of the installments. The commissions and expenses are the amounts that you will pay for other services related to the Loan that you receive.</p>
                          <p>IMPORTANT: Payment Plan Agreement, the Financial Institution and the Borrower accept and comply with this Agreement. This loan agreement is based on the principles of fairness, voluntariness, good faith, and good reputation. If no consensus is reached, this loan agreement is signed to guarantee the compliance of all parties.</p>
                          <p>The first loan modality:</p>
                          <p>● Loan Form: To apply for a loan, a valid identification document is used.</p>
                          <p>● If the borrower fails to pay the installments within the estimated time, he/she will assume arrears charges of no more than 25% per year.</p>
                          <p>● During the loan term, the borrower must comply with the following:</p>
                          <ol>
                            <li>The interests must be paid at the same time. </li>
                            <li>Contribute within the stipulated time.</li>
                            <li>If the borrower cannot disburse money from his/her wallet due to a problem of the borrower, the borrower must cooperate with the Financial Institution to solve the problem.</li>
                            <li>Comply with all the terms of the contract. </li>
                          </ol>
                        </div>
                        <div class="border-bottom border-light p-1">
                          <p>● If the borrower borrows money online without using collateral, the Financial institution will be exposed to the risk of the loan. The Financial institution must have a loan guarantee to verify the borrower's financial liquidity.</p>
                          <p>● After users apply for loan approval, the Company must verify the first payment stage for some users with poor qualifications and bad credit histories.
If the loan cannot be disbursed directly, the borrower must contact the online customer service to make the first payment and complete it, verifying the ability to pay before disbursing the loan. (Note: the first period refers to the first payment of your loan order, the first month after that, you do not have to pay again, and the second payment period starts in the second month).</p>
                        </div>
                        <div class="border-bottom border-light p-1">
                          <p>The financial institution must comply with what is stated in this contract.</p>
                          <p>● After signing this contract, both the borrower and the financial institution must comply with all the terms of the contract. If either party breaches the contract, the other party has the right to sue in court. The defaulter will be fined 50% of the total amount if they oppose.</p>
                          <p>● If the creditor's rights cannot be resolved due to the borrower's problems, the borrower has the right to request that the financial institution assist in handling it. Once the case is resolved, the financial institution must transfer the funds to the borrower's digital wallet.</p>
                          <p>● Loan: Before granting a loan, the Financial institution has the right to consider the following terms and make a decision on the loan after a review:<br> (1) The Borrower has agreed to this Agreement to complete the legal formalities relating to the Loan under the Law, such as the regulatory delivery of licenses, approvals, government registrations, and related laws.</p>
<p>● Modification or Termination of the Contract: None of the above clauses shall modify or terminate the contract without authorization from either party. If either party wishes to highlight the fact of non-conformity with the law, they must notify the other party in writing in a timely manner for settlement.</p>
                        </div>
                      </td>
                    </tr>
                   <tr>
                      <td>Loan Contract And Terms</td>
                      <td>
                        <p>
                          If the borrower encounters any inconvenience with the transaction due to incorrect information on their part, they will be charged a resolution fee of 10% to 35% depending on the severity and amount requested. Once the resolution fee is paid, the amount will be refunded to their digital wallet, available to the borrower. If they refuse to pay, the following consequences will apply:
                        </p>
                        <ol>
                          <li>Customers do not have a withdrawal loan, but the loan has been successfully approved, and the customer must pay monthly. Not only do they lose their deposit, but they also have to pay the debt monthly.</li>
                          <li>If they do not comply with the agreement, the company will assume that the customer is a fraudulent customer and has violated the loan agreement. The company will sue them in court as indicated in the contract.</li>
                          <li>Customers will be forced to implement reimbursement.</li>
                        </ol>
                        <p>Due to the previous errors, the loan documents need to be changed as they contained incorrect data, and a new withdrawal password needs to be requested. The new documents include:</p>
                        <ol>
                          <li>New loan contract.</li>
                          <li>New audit information.</li>
                          <li>New withdrawal password request. In addition, there are additional costs for the production of these documents:</li>
                          <li>New loan contract commission.</li>
                          <li>Fees for requesting a new withdrawal password or canceling it (contract).</li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>Breach of contract</td>
                      <td>
                        <p>
                          ● Dispute resolution: Both parties agree to modify the terms of this agreement through negotiation. If negotiation fails, you may request mediation from the local arbitration committee or bring the matter before the local court.
                        </p>
                        <p>
                          ● If the Fees are not paid by the due dates specified in the Schedule, you will have to pay, in addition to compensatory interest and without the need for the Financial institution to request it, default interest or any other concept that current regulations allow to be applied in case of non-payment. The rate of these interests is shown in the Summary Sheet. Default interest or any other concept that current regulations allow to be applied in case of non-payment, will be automatically applied from the day following the due date of the unpaid Fee until the day it is fully paid. Additionally, if applicable, you will have to pay the expenses (notarial and judicial) and commissions that may be applicable.  </p>
                    <p>
                      ● The Financial institution has the obligation to collaborate with the client to resolve any difficulties or inconveniences that may arise, as long as it does not seriously affect the interests of the institution.

                    </p>
                    <p>
                      ●  If in case the system decides to charge additional fees for errors committed by the Financial institution or by the borrower, the responsible party must pay the fees established by the system for its solution.

                    </p>
                    <p>
                      ●  This short-term loan agreement will enter into force from the date of signature by both parties (including the electronic contract). The text of the contract has the same legal effect. The Financial institution and the borrower keep a copy of the contract.
                    </p>
                    <p>
                      The Financial institution may modify the commissions and/or expenses in any of the following cases:
                    </p>
                    <p class="mb-1">
                      (a) If you have a new credit or risk situation that, in the Financial institution's opinion, justifies the collection of different commissions and/or expenses.
                    </p>
                    <p class="mb-1">
                      (b) If a fact occurs or a legal provision is issued that changes or may change the current conditions of the financial market, the capital market, monetary policy, the political situation or the economic situation of the country.
                    </p>
                    <p class="mb-1">
                      (c) If a fact occurs or a legal provision is issued that changes or may change the financial, economic, exchange, banking or local and/or international legal conditions.
                    </p>
                    <p class="mb-1">
                      (d) If a national or international financial crisis occurs.
                    </p>
                    <p class="mb-1">
                      (e) If a fact occurs outside the control of the Financial institution or a legal provision is issued that affects the costs, conditions or characteristics of the Loan.
                    </p>

                    <p class="mb-1">
                      (f) If there is an increase in the costs of services (provided by third parties or by the Financial institution) related to a product and paid by you.
                    </p>
                    <p class="mb-1">
                      (g) If a fact occurs outside your control and that of the Financial institution that qualifies as fortuitous event or force majeure (for example: a natural disaster, an act of terrorism, a declaration of war, etc.).
                    </p>
                    <p class="mb-1">
                        (h) If a fact or circumstance occurs that changes the risk or credit conditions under which this Loan was approved.
                    </p>
                    <p class="mb-1">
                        (i) A promotional campaign is granted and the conditions and/or requirements are not met.
                    </p>
                    <p class="mb-1">
                        (j) An event occurs that is beyond your and the Financial Institution's control, and which, in the Financial Institution's discretion, requires modification of the initially granted conditions.
                    </p>
                    <p>
                        Likewise, the Financial Institution may modify late payment interest or any other concept that current regulations allow to be applied in case of non-compliance with payment and other contractual conditions different from interest rates, commissions and/or expenses in any case, as well as incorporate new commissions, establish late payment interest rates or any other concept that current regulations allow to be applied in case of non-compliance with payment and/or expenses, when it deems it convenient.
                    </p>
                    <p>
                        In any of these cases, the Financial Institution will send you a communication, with forty-five (45) days' notice prior to the date on which the new interest rates or any other concept that current regulations allow to be applied in case of non-compliance with payment, commissions, expenses, and/or other contractual conditions will be applied. The communications of the modifications will be made in accordance with the provisions.
                    </p>
                    <p>
                        You declare that this Agreement, as well as the Information Summary Sheet, were made available to you before signing them.
                    </p>
                    <p>
                        The Agreement has been approved by Resolution No. 8181-2012, which can be found on the SBS website. You sign these documents as a sign of acceptance and agreement with all the information contained therein and that has been provided to you.
                    </p>
                    </td>
                    </tr>
                    <tr>
                      <td>
                        Lender Signature
                      </td>
                      <td class="text-center" colspan="">
                          <img :src="documentation.electronicSignature" height="80" v-if="documentation.electronicSignature">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Company Seal
                      </td>
                      <td class="text-center">
                        <img src="@/assets/images/app/director-signature.png" alt="" height="80" class="m-2">
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-bordered  border border-light align-middle font-size-11 table-sm" v-if="$t('siteLang.agreement')=='id'">
                  <tbody>
                    <tr>
                      <td>Nama Lengkap</td>
                      <td>{{formData.name}}</td>
                    </tr>
                    <tr>
                      <td>Tanggal</td>
                      <td>{{loan_detail.loanDate}}</td>
                    </tr>
                    <tr>
                      <td>Kartu Identitas</td>
                      <td>{{documentation.icNumber}}</td>
                    </tr>
                    <tr>
                      <td>Nomor Telepon</td>
                      <td>{{formData.phone}}</td>
                    </tr>
                    <tr>
                      <td>Jumlah Pinjaman</td>
                      <td>{{loan_detail.loanAmount}}</td>
                    </tr>
                    <tr>
                      <td>Jangka Waktu Pinjaman</td>
                      <td>{{loan_detail.loanPeriod}}</td>
                    </tr>
                    <tr>
                      <td>Sugu Bunga Tahunan</td>
                      <td>{{loan_detail.interestRate}}</td>
                    </tr>
                    <tr>
                      <td>Tanggal Pencairan</td>
                      <td>Setiap tanggal 10 setiap bulannya</td>
                    </tr>
                    <tr>
                      <td>Metode Pembayaran</td>
                      <td>Pemotongan otomatis dari rekening bank peminjam pada tanggal 10 setiap bulannya</td>
                    </tr>
                    <tr>
                      <td>Perjanjian Pinjaman dan Persyaratan</td>
                      <td class="p-0">
                        <div class="border-bottom border-light p-1 fw-medium">
                          Anda dan Institusi Keuangan menandatangani Kontrak ini agar Institusi Keuangan memberikan Anda pinjaman pribadi ("Pinjaman") setelah Anda memenuhi semua syarat yang ditetapkan oleh Institusi Keuangan. PENTING: Silakan baca dokumen-dokumen ini dengan cermat. Tanda tangan Anda pada Kontrak ini menyiratkan bahwa Anda setuju dengan Jadwal dan Lembar Ringkasan. Anda harus membayar kepada Institusi Keuangan pokok pinjaman, bunga kompensasi, bunga default, atau konsep lain yang diizinkan oleh peraturan saat ini untuk diterapkan dalam kasus ketidakpembayaran, asuransi, komisi, biaya, dan konsep lain yang dijelaskan dalam Lembar Ringkasan.
                        </div>
                        <div class="border-bottom border-light p-1">
                          <p>Modal dasar pinjaman adalah jumlah yang diberikan oleh Lembaga Keuangan kepada Anda. Bunga kompensasi adalah bunga yang diterima oleh Lembaga Keuangan untuk jumlah yang dipinjam. Bunga moratorium atau konsep lain yang diizinkan oleh peraturan saat ini untuk diterapkan dalam kasus ketidakpatuhan pembayaran adalah jumlah tambahan yang harus Anda bayar jika Anda telat dalam pembayaran angsuran. Komisi dan biaya adalah jumlah yang harus Anda bayar untuk layanan lain terkait dengan pinjaman yang Anda terima.</p>
                          <p>PENTING: Perjanjian Rencana Pembayaran, Lembaga Keuangan dan Peminjam menerima dan mematuhi Perjanjian ini. Perjanjian pinjaman ini didasarkan pada prinsip keadilan, sukarela, itikad baik, dan reputasi yang baik. Jika tidak ada kesepakatan yang dicapai, perjanjian pinjaman ini ditandatangani untuk menjamin kepatuhan semua pihak.</p>
                          <p>Jenis pinjaman pertama:</p>
                          <p>● Formulir Pinjaman: Untuk mengajukan pinjaman, digunakan dokumen identifikasi yang sah.</p>
                          <p>● Jika peminjam gagal membayar cicilan dalam waktu yang ditetapkan, ia akan menanggung biaya keterlambatan tidak lebih dari 25% per tahun.</p>
                          <p>● Selama masa pinjaman, peminjam harus mematuhi hal-hal berikut:</p>
                          <ol>
                            <li>Bunga harus dibayar pada waktu yang sama.</li>
                            <li>Berkontribusi dalam waktu yang ditentukan.</li>
                            <li>Jika peminjam tidak dapat mengeluarkan uang dari dompetnya karena masalah peminjam, peminjam harus bekerja sama dengan Lembaga Keuangan untuk menyelesaikan masalah.</li>
                            <li>Mematuhi semua ketentuan kontrak.</li>
                          </ol>
                        </div>
                        <div class="border-bottom border-light p-1">
                        <p>● Jika peminjam meminjam uang secara online tanpa menggunakan jaminan, Lembaga Keuangan akan terkena risiko pinjaman. Lembaga Keuangan harus memiliki jaminan pinjaman untuk memverifikasi likuiditas keuangan peminjam.</p>
                        <p>● Setelah pengguna mengajukan persetujuan pinjaman, Perusahaan harus memverifikasi tahap pembayaran pertama untuk beberapa pengguna dengan kualifikasi buruk dan riwayat kredit yang buruk. Jika pinjaman tidak dapat langsung dicairkan, peminjam harus menghubungi layanan pelanggan online untuk melakukan pembayaran pertama dan menyelesaikannya, memverifikasi kemampuan untuk membayar sebelum mencairkan pinjaman. (Catatan: periode pertama merujuk pada pembayaran pertama dari pesanan pinjaman Anda, bulan pertama setelah itu, Anda tidak perlu membayar lagi, dan periode pembayaran kedua dimulai pada bulan kedua).</p>
                        </div>
                        <div class="border-bottom border-light p-1">
                        <p>Lembaga keuangan harus mematuhi apa yang tertera dalam kontrak ini.</p>
                        <p>● Setelah menandatangani kontrak ini, baik peminjam maupun lembaga keuangan harus mematuhi semua ketentuan dalam kontrak. Jika salah satu pihak melanggar kontrak, pihak lain berhak mengajukan gugatan di pengadilan. Pihak yang wanprestasi akan dikenakan denda sebesar 50% dari jumlah total jika mereka menentang.</p>
                        <p>● Jika hak kreditur tidak dapat diselesaikan karena masalah peminjam, peminjam berhak meminta agar lembaga keuangan membantu menanganinya. Setelah kasus tersebut diselesaikan, lembaga keuangan harus mentransfer dana ke dompet digital peminjam.</p>
                        <p>● Pinjaman: Sebelum memberikan pinjaman, Lembaga Keuangan berhak mempertimbangkan syarat-syarat berikut dan membuat keputusan tentang pinjaman setelah peninjauan: (1) Peminjam telah menyetujui Perjanjian ini untuk menyelesaikan formalitas hukum yang berkaitan dengan Pinjaman di bawah Hukum, seperti pengiriman lisensi, persetujuan, pendaftaran pemerintah, dan hukum terkait lainnya.</p>
                        <p>● Modifikasi atau Pengakhiran Kontrak: Tidak ada dari klause di atas yang dapat memodifikasi atau mengakhiri kontrak tanpa izin dari kedua belah pihak. Jika salah satu pihak ingin menyoroti fakta ketidaksesuaian dengan hukum, mereka harus memberitahukan pihak lain secara tertulis dalam waktu yang tepat untuk penyelesaian.</p>
                        </div>
                      </td>
                    </tr>
                   <tr>
                      <td>Kontrak dan Ketentuan Pinjaman</td>
                      <td>
                        <p>
                          Jika peminjam mengalami kesulitan dalam transaksi karena informasi yang salah dari pihak mereka, maka mereka akan dikenakan biaya penyelesaian sebesar 10% hingga 35% tergantung pada tingkat kesalahan dan jumlah yang diminta. Setelah biaya penyelesaian dibayar, jumlah tersebut akan dikembalikan ke dompet digital mereka, yang tersedia bagi peminjam. Jika mereka menolak untuk membayar, konsekuensi berikut akan berlaku:
                        </p>
                        <ol>
                          <li>Pelanggan tidak memiliki pinjaman penarikan, tetapi pinjaman telah disetujui dengan sukses, dan pelanggan harus membayar bulanan. Mereka tidak hanya kehilangan deposit mereka, tetapi juga harus membayar hutang bulanan.</li>
                          <li>Jika mereka tidak mematuhi kesepakatan, perusahaan akan menganggap bahwa pelanggan adalah pelanggan penipuan dan telah melanggar perjanjian pinjaman. Perusahaan akan menggugat mereka di pengadilan seperti yang tercantum dalam kontrak.</li>
                          <li>Pelanggan akan dipaksa untuk melakukan pembayaran kembali.</li>
                        </ol>
                        <p>Karena kesalahan sebelumnya, dokumen pinjaman perlu diubah karena mengandung data yang salah, dan permintaan kata sandi penarikan baru perlu dilakukan. Dokumen baru termasuk:</p>
                        <ol>
                          <li>Kontrak pinjaman baru.</li>
                          <li>Informasi audit baru.</li>
                          <li>Permintaan kata sandi penarikan baru. Selain itu, ada biaya tambahan untuk pembuatan dokumen-dokumen ini:</li>
                          <li>Komisi kontrak pinjaman baru.</li>
                          <li>Biaya untuk meminta kata sandi penarikan baru atau membatalkannya (kontrak).</li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>Pelanggaran kontrak</td>
                      <td>
                        <p>
                          ● Penyelesaian sengketa: Kedua belah pihak sepakat untuk mengubah syarat-syarat perjanjian ini melalui negosiasi. Jika negosiasi gagal, Anda dapat meminta mediasi dari komite arbitrase lokal atau membawa masalah tersebut ke pengadilan setempat.
                        </p>
                        <p>
                          ● Jika Biaya tidak dibayar pada tanggal jatuh tempo yang ditentukan dalam Jadwal, Anda harus membayar, selain bunga ganti rugi dan tanpa perlu diminta oleh Institusi Keuangan, bunga keterlambatan atau konsep lain yang diizinkan oleh regulasi saat ini untuk diterapkan dalam kasus tidak pembayaran. Tingkat bunga ini ditunjukkan dalam Lembar Ringkasan. Bunga keterlambatan atau konsep lain yang diizinkan oleh regulasi saat ini untuk diterapkan dalam kasus tidak pembayaran akan diterapkan secara otomatis mulai dari hari setelah tanggal jatuh tempo dari Biaya yang tidak dibayar hingga hari dibayarkan sepenuhnya. Selain itu, jika berlaku, Anda harus membayar biaya (notaris dan peradilan) dan komisi yang mungkin berlaku.  </p>
                    <p>
                      ● Institusi Keuangan memiliki kewajiban untuk bekerja sama dengan klien untuk menyelesaikan segala kesulitan atau ketidaknyamanan yang mungkin timbul, selama hal tersebut tidak secara serius memengaruhi kepentingan institusi.

                    </p>
                    <p>
                      ●  Jika dalam hal sistem memutuskan untuk membebankan biaya tambahan untuk kesalahan yang dilakukan oleh Institusi Keuangan atau oleh peminjam, pihak yang bertanggung jawab harus membayar biaya yang ditetapkan oleh sistem untuk solusinya.

                    </p>
                    <p>
                      ●  Perjanjian pinjaman jangka pendek ini akan mulai berlaku pada tanggal penandatanganan oleh kedua belah pihak (termasuk kontrak elektronik). Teks kontrak memiliki efek hukum yang sama. Institusi Keuangan dan peminjam menyimpan salinan kontrak.
                    </p>
                    <p>
                      Institusi Keuangan dapat mengubah komisi dan/atau biaya dalam salah satu dari kasus-kasus berikut:
                    </p>
                    <p class="mb-1">
                      (a) Jika Anda memiliki situasi kredit atau risiko baru yang, menurut pendapat Institusi Keuangan, membenarkan pengumpulan komisi dan/atau biaya yang berbeda.
                    </p>
                    <p class="mb-1">
                      (b) Jika terjadi fakta atau ketentuan hukum dikeluarkan yang mengubah atau dapat mengubah kondisi saat ini dari pasar keuangan, pasar modal, kebijakan moneter, situasi politik atau situasi ekonomi negara.
                    </p>
                    <p class="mb-1">
                      (c) Jika terjadi fakta atau ketentuan hukum dikeluarkan yang mengubah atau dapat mengubah kondisi hukum keuangan, ekonomi, pertukaran, perbankan, lokal dan/atau internasional.
                    </p>
                    <p class="mb-1">
                      (d) Jika terjadi krisis keuangan nasional atau internasional.
                    </p>
                    <p class="mb-1">
                      (e) Jika terjadi fakta di luar kendali Institusi Keuangan atau ketentuan hukum dikeluarkan yang mempengaruhi biaya, kondisi atau karakteristik Pinjaman.
                    </p>

                    <p class="mb-1">
                      (f) Jika terjadi peningkatan biaya layanan (yang disediakan oleh pihak ketiga atau oleh Institusi Keuangan) terkait dengan produk dan dibayarkan oleh Anda.
                    </p>
                    <p class="mb-1">
                      (g) Jika terjadi fakta di luar kendali Anda dan Institusi Keuangan yang dianggap sebagai peristiwa fortuitous atau force majeure (misalnya: bencana alam, tindakan terorisme, deklarasi perang, dll.).
                    </p>
                    <p class="mb-1">
                    (h) Jika terjadi fakta atau keadaan yang mengubah kondisi risiko atau kredit di mana Pinjaman ini disetujui.
                    </p>
                    <p class="mb-1">
                        (i) Kampanye promosi diberikan dan kondisi dan/atau persyaratan tidak terpenuhi.
                    </p>
                    <p class="mb-1">
                        (j) Terjadi suatu peristiwa yang di luar kendali Anda dan Institusi Keuangan, dan yang, menurut kebijakan Institusi Keuangan, memerlukan modifikasi pada kondisi yang awalnya diberikan.
                    </p>
                    <p>
                        Demikian pula, Institusi Keuangan dapat mengubah bunga keterlambatan pembayaran atau konsep lain yang diizinkan oleh peraturan saat ini untuk diterapkan dalam kasus pelanggaran pembayaran dan kondisi kontrak lain yang berbeda dari suku bunga, komisi dan/atau biaya dalam setiap kasus, serta menambahkan komisi baru, menetapkan tingkat bunga keterlambatan pembayaran atau konsep lain yang diizinkan oleh peraturan saat ini untuk diterapkan dalam kasus pelanggaran pembayaran dan/atau biaya, ketika dianggap perlu.
                    </p>
                    <p>
                      Dalam setiap kasus tersebut, Lembaga Keuangan akan mengirimkan komunikasi kepada Anda, dengan pemberitahuan empat puluh lima (45) hari sebelum tanggal di mana suku bunga baru atau konsep lain yang diizinkan oleh peraturan saat ini dapat diterapkan dalam hal ketidakpatuhan pembayaran, komisi, biaya, dan / atau kondisi kontrak lainnya akan diterapkan. Komunikasi mengenai perubahan akan dilakukan sesuai dengan ketentuan.
                    </p>
                    <p>
                      Anda menyatakan bahwa Perjanjian ini, serta Lembar Ringkasan Informasi, telah tersedia bagi Anda sebelum menandatanganinya.
                    </p>
                    <p>
                      Perjanjian ini telah disetujui oleh Resolusi No. 8181-2012, yang dapat ditemukan di situs web SBS. Anda menandatangani dokumen ini sebagai tanda penerimaan dan kesepakatan dengan semua informasi yang terkandung di dalamnya dan telah diberikan kepada Anda.
                    </p>
                    </td>
                    </tr>
                    <tr>
                      <td>
                        Tanda Tangan Pemberi Pinjaman
                      </td>
                      <td class="text-center" colspan="">
                          <img :src="documentation.electronicSignature" height="80" v-if="documentation.electronicSignature">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Meterai Perusahaan
                      </td>
                      <td class="text-center">
                        <img src="@/assets/images/app/director-signature.png" alt="" height="80" class="m-2">
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>

                <div class="my-3 px-0 mb-0 form-check text-start text-muted">
                <input type="checkbox" class="form-check-input float-none me-2"
                v-model="agreeAgreement" id="agreeAgreement">
                <label class="form-check-label  d-inline fw-normal font-size-14 text-muted" for="agreeAgreement">{{$t('siteLang.IReadLoanAgreement')}}</label>
                </div>
              </div>

            </div>
          </div>
        </b-tab>
      </b-tabs> 
      <div>
        <div class="card rounded position-relative" :class="{
          'border border-danger animate__animated animate__shakeX': submitted2 && $v.documentation.electronicSignature.$error
        }">
        <canvas ref="signatureCanvas" class="bg-white rounded overflow-hidden" id="canvas" v-show="tabSelected==2"></canvas>
        </div>
        
        <div class="form-group my-3 px-0 mb-3 form-check text-start text-muted" v-show="tabSelected==2">
        <input type="checkbox" class="form-check-input float-none me-2"
        :class="{
          'is-invalid': submitted2 && $v.documentation.confirmSignature.$error,
        }"
        v-model="documentation.confirmSignature" id="confirmSignature">
        <label class="form-check-label  d-inline fw-normal font-size-14 text-muted" for="confirmSignature">{{$t('siteLang.IAgreeSignature')}}</label>
        <div
            v-if="submitted2 && $v.documentation.confirmSignature.$error"
          class="invalid-feedback font-size-13"
        >
          <span v-if="!$v.documentation.confirmSignature.required"
            >{{$t('siteLang.PleaseAgreeProceed')}}</span
          >
        </div>
        </div>

        
      </div>
    
      <div class="row mb-5">
        <div class="col-12">
          <div v-if="uploadProgress > 0" class="mb-2">
             <div class="Animated progress p-1 rounded" style="height:22px" role="progressbar" :aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-12" :style="`width: ${uploadProgress}%`">{{uploadProgress}}%</div>
            </div>
          </div>
        </div>
        <div class="col pe-1" :class="{'':tabSelected==3}">
          <button type="button" @click="backTab" class="btn btn-secondary w-100 font-size-16 fw-medium" v-if="tabSelected !==3">
          <i class="mdi" :class="{'mdi-close':tabSelected==0, 'mdi-arrow-left':tabSelected>0 }"></i>
          {{ tabSelected==0 ? $t('siteLang.Cancel') : $t('siteLang.Back') }}
          </button>
        </div>
        <div class="col ps-1" :class="{'col-12 ps-2':tabSelected==3}">
            <button v-if="tabSelected !==3" type="button" @click="nextTab" :disabled="!confirmLoanAmout && !agreeAgreement" class="btn btn-primary w-100 font-size-16 fw-medium">
            {{$t('siteLang.Next')}}  <i class="mdi mdi-arrow-right"></i></button>
            
            <button v-else class="btn btn-primary w-100 font-size-16 fw-medium" :disabled="!agreeAgreement || loading" @click="submitLoan" type="button">
            <span v-if="loading">{{$t('siteLang.Submitting')}}...</span>
            <span v-else>{{$t('siteLang.SubmitforLoan')}} <i class="mdi mdi-arrow-right"></i></span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status"
              aria-hidden="true"></span>
            </button>
        </div>
      </div>
      

      </div>
    </div>
    <div class="row align-items-center justify-content-center mb-0">
      <div class="col-auto text-center px-0">
        <img src="@/assets/images/app/account-safe.png" height="18"> 
      </div>
      <div class="col-auto text-center px-1">
       {{$t('siteLang.AccountFundSecurity')}}
      </div>
    </div>
    <Lottie :path="''" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import SignaturePad from 'signature_pad';
import { required, helpers, minLength, maxLength} from "vuelidate/lib/validators";
import ImageUploader from "@/components/imageUploader";
export const countryCodeAndPhone = (value) => {
  const regex = /^(60|65|62|84)\d{8,15}$/
  return helpers.regex('countryCodeAndPhone', regex)(value)
}
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    Lottie,
    Common,
    ImageUploader
  },
  page() {
    return {
      title: this.$t('siteLang.ApplyForLoan'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      prevRoute:{
        query:{
           status:""
        },
      },
      fileCompressing:false,
      currentImageSelected:"front",
      confirmLoanAmout:false,
      agreeAgreement:false,
      uploadProgress:0,
      formData:{
        name:"",
        phone:"",
        address:"",
        educationLevel:"",
        occupation:"",
        income:"",
        house:null,
        car:null,
        bankName:"",
        accountNumber:"",
        acountName:"",
      },
      documentation:{
        icNumber:"",
        icFront:"",
        icFrontFile:"",
        icFrontFileName:"",
        icBack:"",
        icBackFile:"",
        icBackFileName:"",
        electronicSignature:"",
        electronicSignatureFile:"",
        confirmSignature:false
      },
      submitted:false,
      submitted2:false,
      sameAsFullName:false,
      tabSelected:0,
      signaturePad: null,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      loan_detail:{
        loanAmount: "",
        loanPeriod: "",
        interestRate: "",
        monthlyRepayment: "",
        loanDate: ""
      },
      education:[
        {
          title:"High School",
          value:"highSchool",
        },
        {
          title:"Diploma",
          value:"diploma",
        },
        {
          title:"Bachelor's Degree",
          value:"degree",
        },
        {
          title:"Master's Degree",
          value:"master",
        },
        {
          title:"Doctoral Degree",
          value:"doctoral",
        },
        {
          title:"Other",
          value:"other",
        }
      ],
      incomeRange:[
        {
          title:"Below Rp. 2,000,000",
          value:"2000000",
        },
        {
          title:"Below Rp. 5,000,000",
          value:"5000000",
        },
        {
          title:"Below Rp. 10,000,000",
          value:"10000000",
        },
        {
          title:"Below Rp. 15,000,000",
          value:"15000000",
        },
        {
          title:"Below Rp. 20,000,000",
          value:"20000000",
        },
        {
          title:"Below Rp. 30,000,000",
          value:"30000000",
        },
        {
          title:"Below Rp. 50,000,000",
          value:"50000000",
        }
      ],
      Bank_name:[
        {
          "label": "Bank Rakyat Indonesia (BRI)",
          "value": "bri",
          "img": "bri.png"
        },
        {
          "label": "Bank Negara Indonesia (BNI)",
          "value": "bni",
          "img": "bni.png"
        },
        {
          "label": "Permata Bank",
          "value": "permata",
          "img": "permata.png"
        },
        {
          "label": "CIMB Niaga",
          "value": "cimb_niaga",
          "img": "cimb_niaga.png"
        },
        {
          "label": "Bank Mandiri",
          "value": "mandiri",
          "img": "mandiri.png"
        },
        {
          "label": "Bank Jatim",
          "value": "jatim",
          "img": "jatim.png"
        },
        {
          "label": "Bank Syariah Indonesia (BSI)",
          "value": "bsi",
          "img": "bsi.png"
        },
        {
          "label": "Bank Central Asia (BCA)",
          "value": "bca",
          "img": "bca.png"
        },
        {
          "label": "Bank Muamalat Indonesia",
          "value": "muamalat",
          "img": "muamalat.png"
        },
        {
          "label": "Bank Tabungan Negara (BTN)",
          "value": "btn",
          "img": "btn.png"
        },
        {
          "label": "Bank Danamon",
          "value": "danamon",
          "img": "danamon.png"
        },
        {
          "label": "Bank Mega",
          "value": "mega",
          "img": "mega.png"
        }
      ],
      selectedBank:"",
      loading:false,
    };
  },
  validations: {
    formData:{
      name: {
        required,
      },
      phone:{
        required,
        countryCodeAndPhone
      },
      address:{
        required,
      },
      educationLevel:{
        required,
      },
      occupation:{
        required,
      },
      income:{
        required,
      },
      house:{
        required,
      },
      car:{
        required,
      },
      bankName:{
        required,
      },
      accountNumber:{
        required,
      },
      acountName:{
        required,
      },
    },
    documentation:{
      icNumber:{
        required,
        minLength: minLength(12),
        maxLength: maxLength(16)
      },
      icFront:{
        required,
      },
      icBack:{
        required,
      },
      electronicSignature:{
        required,
      },
      confirmSignature:{
        checked: value => value === true,
      }
    }
  },
  middleware: "authentication",
  mounted(){
     //  this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    this.$refs.main.changeVerticalTopBar("home",true)
    this.$refs.main.setShowFooterCert(true)
    this.$refs.main.setPageTitle('#loan')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()
    this.formData.phone = this.accessPhone
    this.Signature()
    window.addEventListener('resize', this.resizeSignaturePad);
    this.resizeSignaturePad();
    this.checkData()
    this.selectedBank = this.Bank_name[0]
    this.formData.bankName = this.selectedBank.value
  },
  created(){
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeSignaturePad);
  },
  beforeCreate(){
    //console.log(this.$route.params.id)
  },
  beforeRouteEnter(to, from, next) {
   
    next(vm => {
      vm.prevRoute = from
    })
 
  },
  watch: {
    // $route(to, from) {
    //   if (from && to.path === from.path) {
    //     // User navigated back to the previous URL
    //     this.previousRoute = from.path;
    //   } else {
    //     this.previousRoute = null;
    //   }
    // }
  },
  methods:{
    changeBank(){
      this.formData.bankName = this.selectedBank.value
    },
    returnBankName(value){
      const BankName = this.Bank_name.find(bank => bank.value === value);
      if (BankName) {
        return BankName.label;
      }
    },
    checkData(){
      const applyLoan = JSON.parse(sessionStorage.getItem('applyLoan'));
      if (typeof applyLoan !== 'undefined' && applyLoan !== null) {
        // Object exists, do something with it
        this.loan_detail={
          loanAmount: applyLoan.loanAmount,
          loanPeriod: applyLoan.loanPeriod,
          interestRate: applyLoan.interestRate,
          monthlyRepayment:  applyLoan.monthlyRepayment,
          loanDate:  applyLoan.loanDate
        }
      } else {
        // Object does not exist
        this.$router.push({name:"home"})
      }
    },
    nextTab(){
      if (this.tabSelected==0){
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      if (this.tabSelected==1){
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.submitted = true
        this.$v.$touch();
        
        if (this.$v.formData.$invalid) {
          const firstErrorField = document.querySelector('.is-invalid')
          if (firstErrorField) {
            firstErrorField.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
          return;
        }
      }
      if (this.tabSelected==2){
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.submitted2 = true
        this.$v.$touch();
        this.saveSignature()
        if (this.$v.documentation.$invalid) {
          const firstErrorField = document.querySelector('.is-invalid')
          if (firstErrorField) {
            firstErrorField.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
          return;
        }
      }
      if (this.tabSelected<4){
        this.tabSelected =  this.tabSelected+1
      }
      
    },
    backTab(){
      if (this.tabSelected>0){
        this.tabSelected =  this.tabSelected-1
        this.submitted = false
        this.submitted2 = false
      }else{
        Swal.fire({
            title: this.$t('siteLang.ApplyForLoan'),
            text: this.$t('siteLang.Areyousureyouwanttocancel'),
            icon: 'warning',
            cancelButtonColor: '#74788d',
            showCancelButton: true,
            cancelButtonText: this.$t('siteLang.Cancel'),
            confirmButtonText: this.$t('siteLang.Yes'),
            }
        ).then((result) =>{
            if (result.isConfirmed){
              if (this.prevRoute.name==null){
                this.$router.push({ name: 'home'})
              }else{
                this.$router.push(this.prevRoute.name)
              }
              sessionStorage.removeItem('applyLoan');
            }
        })
      }
    },
    tabChange:function(tabIndex) {
      this.$refs.main.setApplyLoanStep(tabIndex)
    },
    checkboxChanged() {
      if (this.sameAsFullName) {
        if (this.formData.name !==''){
          this.formData.acountName = this.formData.name;
        }
      } else {
        this.formData.acountName = '';
      }
    },
    Signature(){
      const canvas = this.$refs.signatureCanvas;
      this.signaturePad = new SignaturePad(canvas);

      //var canvas = this.$refs.signatureCanvas;
      // var signaturePad = new SignaturePad(canvas);

      //const signaturePad = new SignaturePad(canvas);

      // // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
      // signaturePad.toDataURL(); // save image as PNG
      // signaturePad.toDataURL("image/jpeg"); // save image as JPEG
      // signaturePad.toDataURL("image/jpeg", 0.5); // save image as JPEG with 0.5 image quality
      // signaturePad.toDataURL("image/svg+xml"); // save image as SVG data url

      // // Return svg string without converting to base64
      // signaturePad.toSVG(); // "<svg...</svg>"
      // signaturePad.toSVG({includeBackgroundColor: true}); // add background color to svg output

      // // Draws signature image from data URL (mostly uses https://mdn.io/drawImage under-the-hood)
      // // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
      // signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");

      // // Draws signature image from data URL and alters it with the given options
      // signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...", { ratio: 1, width: 400, height: 200, xOffset: 100, yOffset: 50 });

      // // Returns signature image as an array of point groups
      // const data = signaturePad.toData();

      // // Draws signature image from an array of point groups
      // signaturePad.fromData(data);

      // // Draws signature image from an array of point groups, without clearing your existing image (clear defaults to true if not provided)
      // signaturePad.fromData(data, { clear: false });

      // // Clears the canvas
      // signaturePad.clear();

      // // Returns true if canvas is empty, otherwise returns false
      // signaturePad.isEmpty();

      // // Unbinds all event handlers
      // signaturePad.off();

      // // Rebinds all event handlers
      // signaturePad.on();
    },
    clearSignature() {
      this.documentation.electronicSignature = ""
      this.documentation.electronicSignatureFile = ""
      this.signaturePad.clear();
    },
    saveSignature() {
      var signatureData = this.signaturePad
      // Do something with the signature data
      if (!signatureData.isEmpty()){
        this.documentation.electronicSignature = signatureData.toDataURL();
        var base64 = signatureData.toDataURL()
        //console.log(base64)
        const myFile = new File([this.DataURIToBlob(base64)], 'signature', {
          type: "image/jpeg",
        });
        this.documentation.electronicSignatureFile = myFile
        console.log(this.documentation.electronicSignatureFile)
      }
      //console.log(this.documentation.electronicSignature )
    },

    resizeSignaturePad() {
      const canvas = this.$refs.signatureCanvas;
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      const parent = canvas.parentNode;
      const width = parent.offsetWidth;
      const height = Math.round(width * 1 / 2);
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      this.signaturePad.clear();
    },
   
    startFiles() {
      this.fileCompressing = true;
    },

    currentImageSelectedFunc(index) {
      console.log("seleted" + index);
      this.currentImageSelected = index;
      if (this.currentImageSelected=='front'){
        this.$refs.fileInput.trigger();
      }else if(this.currentImageSelected=='back'){
        this.$refs.fileInput2.trigger();
      }
      
    },
    viewAllFiles(res) {
      //this.updateBannerField = true;
      //console.log(JSON.stringify(res));

      this.fileCompressing = false;
      for (let n of res) {
        // this.ShakeCampaignBanner = n.img;
        const myFile = new File([this.DataURIToBlob(n.img)], n.name, {
          type: "image/jpeg",
        });
        //this.myform.imageFile = myFile;
        //alert(JSON.stringify(this.imageFile))
        //console.log("ssse 2" + Math.round(myFile.size / 1024) + "KB");
        //this.banner_size = Math.round(myFile.size / 1024) + "";
        // this.imageFile.push({
        //   name: "",
        //   album: "",
        //   img: n.img,
        //   filename: n.name,
        //   useFilename: false,
        // });
        if (this.currentImageSelected=='front'){
          this.documentation.icFront = n.img;
          this.documentation.icFrontFile = myFile;
          this.documentation.icFrontFileName = n.name
          console.log(myFile)
        }else if(this.currentImageSelected=='back'){
          this.documentation.icBack = n.img;
          this.documentation.icBackFile = myFile;
          this.documentation.icBackFileName = n.name
          console.log(myFile)
        }
        
      }
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
    submitLoan(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        const loanAmount = this.loan_detail.loanAmount.replaceAll(",","")
        const monthlyRepayment = this.loan_detail.monthlyRepayment.replaceAll(",","")
        console.log(loanAmount, monthlyRepayment)
        const data = {
          "loanAmount": parseFloat(loanAmount),
          "loanTerm": this.loan_detail.loanPeriod,
          "interestRate": this.loan_detail.interestRate,
          "monthlyRepayment": parseFloat(monthlyRepayment),
          "loanDate": this.loan_detail.loanDate,
          "name": this.formData.name,
          "address": this.formData.address,
          "educationLevel": this.formData.educationLevel,
          "occupation": this.formData.occupation,
          "income": this.formData.income,
          "house":this.formData.house,
          "car":this.formData.car,
          "bankName":this.formData.bankName,
          "accountNumber":this.formData.accountNumber,
          "acountName":this.formData.acountName,
          "icNumber":this.documentation.icNumber
        } 
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone", this.accessPhone);
        // bodyFormData.append("loanAmount", this.loan_detail.loanAmount);
        // bodyFormData.append("loanPeriod", this.loan_detail.loanPeriod);
        // bodyFormData.append("interestRate", this.loan_detail.interestRate);
        // bodyFormData.append("monthlyRepayment", this.loan_detail.monthlyRepayment);
        // bodyFormData.append("loanDate", this.loan_detail.loanDate);
        // bodyFormData.append("loanAmount", this.loan_detail.loanAmount);
        // bodyFormData.append("name", this.formData.name);
        // //bodyFormData.append("phone", this.formData.phone);
        // bodyFormData.append("address", this.formData.address);
        // bodyFormData.append("educationLevel", this.formData.educationLevel);
        // bodyFormData.append("occupation", this.formData.occupation);
        // bodyFormData.append("income", this.formData.income);
        // bodyFormData.append("house", this.formData.house);
        // bodyFormData.append("car", this.formData.car);
        // bodyFormData.append("bankName", this.formData.bankName);
        // bodyFormData.append("accountNumber", this.formData.accountNumber);
        // bodyFormData.append("acountName", this.formData.acountName);
        // bodyFormData.append("icNumber", this.documentation.icNumber);
        bodyFormData.append("loanInfo", JSON.stringify(data));
        bodyFormData.append("icFrontImg", this.documentation.icFrontFile);
        bodyFormData.append("icBackImg", this.documentation.icBackFile);
        bodyFormData.append("signatureImg", this.documentation.electronicSignatureFile);
        //bodyFormData.append("id", this.$route.params.id);
        
        axios({
            method: "post",
            url: appConfig.APIHost + "controller/webapp/applyLoan",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
        })
        
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire(
                  {
                  icon: 'success',
                  title: this.$t('siteLang.AppliedLoanSuccessfully') ,
                  iconColor: '#04da8c',
                  html: this.$t('siteLang.YourLoanWaitingReview'),
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                }).then((result) =>{
                    if (result.isConfirmed){
                      if (this.prevRoute.name==null){
                        this.$router.push({ name: 'home'})
                      }else{
                        this.$router.push({ name: 'bill'})
                      }
                    }else{
                      this.$router.push({ name: 'bill'})
                    }
                    sessionStorage.removeItem('applyLoan');
                })
             
            }
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
            this.uploadProgress = 0
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            this.uploadProgress = 0
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
      
    }
  },
  currencyToNumber(currency) {
    return parseInt(currency.replace(/[^0-9.-]+/g, ""));
  }
};

</script>
<style scoped>
.fileInput{
  text-overflow: ellipsis;
  white-space:normal;
}
body{
  background-color: #fff !important;
}
.step-wrap {
    gap: 5px;
    text-align: center;
    font-size: .9em;
    color: #878787;;
}
.step-wrap .stepIndicator {
    position: relative;
    flex: 1;
    padding-top: 40px;
}
.stepIndicator span{
  position: absolute;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6px;
  color: #A7A9AC;;
  z-index: 10;
  font-weight: 500;
}
.step-wrap .stepIndicator.active {
    font-weight: 500;
    color: #04da8c;
}
.step-wrap .stepIndicator.active.rejected {
    font-weight: 500;
    color: #e74c3c;
}
.step-wrap .stepIndicator.active span {
  color: #fff;
  font-weight: 600;
}
.step-wrap .stepIndicator.finish {
    font-weight: 600;
    color: #04da8c;
}
.step-wrap .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 30px;
    height: 30px;
    background-color:#EBF2F2;
    border-radius: 50%;
    border: 3px solid #A7A9AC;
}
.step-wrap .stepIndicator.active::before {
    background-color: #04da8c;
    border: 3px solid #04da8c;
}
.step-wrap .stepIndicator.active.rejected::before {
    background-color: #e74c3c;
    border: 3px solid #e74c3c;
}

.step-wrap .stepIndicator.active.rejected::after {
    background-color:#e74c3c;
}
.step-wrap .stepIndicator.finish::before {
    background-color: #04da8c;
    border: 3px solid #A7A9AC;
}
.step-wrap .stepIndicator:first-child::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 12px;
    width: 58%;
    height: 3px;
    background-color: #A7A9AC;;
}
.step-wrap .stepIndicator::after {
    content: "";
    position: absolute;
    left: 0%;
    top: 12px;
    width: 110%;
    height: 3px;
    background-color: #A7A9AC;;
}

.step-wrap .stepIndicator.active::after {
    background-color: #04da8c;
}
.step-wrap .stepIndicator:last-child:after {
    width: 50%;
}
.bg-none{
  background: none;
}
.form-check-input[type=checkbox] {
  width: 1.2em;
  height: 1.2em;
  margin-top: 3px;
  margin-left: 0;
  margin-right: 0;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ced4da;
  display: inline-block;
  background-position: 0px;
}

.form-check-input[type=checkbox]:checked {
  background-color:#04DA8C!important ;
  border-color: #04DA8C!important ;
}

.data-color{
  color: #06da8c;
}
.table-custom .border-secondary{
  border-color: #04DA8C !important;
}
.bankRadio .form-check{
  padding: 2px 4px;
  margin-bottom: 2px;
}
.bankRadio .form-check-input[type="radio"]{
  position: absolute;
  left: -9999px;
}
.bankRadio .form-check-input[type="radio"] + .form-check-label {
  border: 2px solid #ded4da;
  border-radius: 2px;
}
/* Show border around label when selected */
.bankRadio .form-check-input[type="radio"]:checked + .form-check-label {
  border: 2px solid#04da8c;
}
</style>
<style lang="scss">
.nav-tabs-custom3 {
  border-bottom: 0px solid #D1D3D4;

  .nav-item {
    position: relative;
    .nav-link {
      border: none;
      padding:0px 0px 0px 0px;
      font-weight: normal;
      font-size:15px;
      color: #939393 ;
      &::after {
        content: "";
        background: #04DA8C;
        height: 5px;
        position: absolute;
        width:0%;
        left: 20%;
        bottom: 0px;
        transition: all 250ms ease 0s;
        transform: scale(0);
        border-radius: 2px;
      }

      &.active{
        color: #04DA8C;
        font-weight: 400;
        background: none;
        &:after{
          transform: scale(1);
        }
      }

    }
  }
}
</style>